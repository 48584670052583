import { IBM_Plex_Mono } from "next/font/google";
import { extendTheme } from "@chakra-ui/react";
import localFont from "next/font/local";

const sf = localFont({
  src: "../res/fonts/SF-Pro-Display-Regular.otf",
  weight: "400",
});

const coa = localFont({ src: "../res/fonts/Corpo-A.ttf" });
const cos = localFont({ src: "../res/fonts/Corpo-S.ttf" });
const cotc = localFont({
  src: "../res/fonts/MBCorpoATitleCond-Regular.otf",
});

const plex = IBM_Plex_Mono({ weight: "400", subsets: ["latin"] });

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      "html, body": {
        //
      },
      "::-webkit-scrollbar": {
        w: "0.5rem",
        h: "0.5rem",
        borderRadius: "0.5rem",
        padding: "4px",
        bgColor: /* "var(--chakra-colors-gray-100)" */ "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        bgColor: "var(--chakra-colors-gray-300)",
        borderRadius: "0.5rem",
        margin: "2px",
      },
      "*": {
        scrollbarColor: "lightgray transparent",
        /* "var(--chakra-colors-brandFull) var(--chakra-colors-gray-200)", */
      },
    },
  },
  components: {
    Button: {
      defaultProps: {
        colorScheme: "brand",
        variant: "loose",
        size: "sm",
      },
      variants: {
        tight: {
          rounded: "full",
          paddingY: 1,
          paddingX: 8,
          h: "auto",
          backgroundColor: "brand.500",
          _hover: {
            backgroundColor: "brand.600",
            _disabled: {
              backgroundColor: "gray.500",
            },
          },
          _active: {
            backgroundColor: "brand.700",
          },
          _disabled: {
            backgroundColor: "gray.500",
          },
        },

        loose: {
          rounded: "full",
          paddingY: 2,
          paddingX: 4,
          h: "auto",
          backgroundColor: "brand.500",
          color: "white",
          _hover: {
            backgroundColor: "brand.600",
            _disabled: {
              backgroundColor: "gray.500",
            },
          },
          _active: {
            backgroundColor: "brand.700",
          },
          _disabled: {
            backgroundColor: "gray.500",
          },
        },

        loose_outline: {
          rounded: "full",
          paddingY: 2,
          paddingX: 4,
          h: "auto",
          backgroundColor: "transparent",
          colorScheme: "brand",
          outline: "1px solid",
          color: "brand.500",
          _hover: {
            color: "white",
            backgroundColor: "brand.500",
            _disabled: {
              color: "gray.500",
            },
          },
          _active: {
            color: "brand.700",
          },
          _disabled: {
            color: "gray.500",
          },
        },

        link: {
          padding: 2,
          paddingX: 4,
          h: "auto",
          backgroundColor: "transparent",
          _hover: {
            textDecoration: "underline",
            backgroundColor: "transparent",
            _disabled: {
              backgroundColor: "transparent",
            },
          },
          _active: {
            backgroundColor: "transparent",
          },
          _disabled: {
            backgroundColor: "transparent",
          },
        },

        code: {
          outline: "1px solid white",
          rounded: "none",
          fontSize: "xs",
          py: 2,
        },
      },
    },
    Heading: {
      variants: {
        md: {
          fontSize: 24,
          fontFamily: "corpoA",
          fontWeight: 400,
        },
        mono: {
          fontSize: 24,
          fontFamily: "mono",
          fontWeight: 400,
        },
      },
    },
    Text: {
      variants: {
        largeMessage: {
          fontSize: 24,
          fontStyle: "italic",
          fontWeight: 400,
          fontFamily: "corpoA",
        },
      },
    },
    Menu: {
      defaultProps: {
        variant: "default",
      },
      variants: {
        code: {
          button: {
            bg: "red.400",
            fontFamily: "mono",
            rounded: "none",
          },
          list: {
            bg: "black",
            rounded: "none",
            fontFamily: "mono",
          },
          item: {
            bg: "transparent",
            fontFamily: "mono",
            fontSize: "xs",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            _hover: { bg: "whiteAlpha.200", textDecoration: "underline" },
            icon: {
              display: "flex",
              alignItems: "center",
            },
          },
        },
        default: {
          groupTitle: {
            marginBottom: 0,
            padding: 0,
            marginX: 3,
            fontSize: "lg",
          },
          list: {
            textColor: "black",
          },
        },
      },

      Icon: {
        variants: {
          center: {
            display: "flex",
            alignItems: "center",
          },
        },
      },
    },
  },
  fonts: {
    ios: sf.style.fontFamily,
    corpoA: coa.style.fontFamily,
    corpoS: cos.style.fontFamily,
    corpoCondensed: cotc.style.fontFamily,
    body: `${cos.style.fontFamily} , 'sans-serif'`,
    heading: `${cos.style.fontFamily} , 'sans-serif'`,
    mono: plex.style.fontFamily,
  },
  shadows: {
    outline: "none",
    device: "0px 0px 15px 0px rgba(0,0,0,0.7)",
    highlight: "0px 0px 10px var(--chakra-colors-brand-400)",
  },
  colors: {
    brand: {
      "50": "#E5F4FF",
      "100": "#B8E1FF",
      "200": "#8ACEFF",
      "300": "#5CBBFF",
      "400": "#2EA8FF",
      "500": "#0095FF",
      "600": "#0077CC",
      "700": "#005999",
      "800": "#003C66",
      "900": "#001E33",
    },
    pink: {
      "50": "#FFE5EC",
      "100": "#FFB8C8",
      "200": "#FF8AA5",
      "300": "#FF5C82",
      "400": "#FF2E5F",
      "500": "#FF003C",
      "600": "#CC0030",
      "700": "#990024",
      "800": "#660018",
      "900": "#33000C",
    },
    brandFull: "#007CD4",
    brandUi: "#00AEED",
    pinkHighlight: "rgba(255, 60, 106, 1)",
  },
});

export default theme;
